.toggle{
    display: flex;
    justify-content: space-between;
    border: 3px solid var(--orange);
    position: relative;
    padding: 4px;
    cursor: pointer;
    border-radius: 30%;
}

.toggle>*{
    width: 1.4rem;
    height: 1.4rem;
    color: var(--orange);
}

.t-button{
    border-radius: 100%;
    background: var(--orange);
    position: absolute;
}